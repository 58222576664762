import '@mdi/font/css/materialdesignicons.css'
import _ from 'lodash'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { importAll } from '@/assets/js/utils/svg-loader'
import th from 'vuetify/lib/locale/th'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { th },
    current: 'th'
  },
  icons: {
    values: _.assign(
      importAll(
        require.context('@/assets/svg/icons/', true, /\.svg$/),
        'snt'
      )
    )
  },
  theme: {
    light: true,
    dark: false,
    themes: {
      light: {
        primary: '#1BA7E1',
        error: '#F04D4D', // DF6C4F
        success: '#2AB59C',
        warning: '#EDA437',
        secondary: '#6C6C6C',
        background: '#F4F6FA'
      },
      dark: {
        primary: '#1BA7E1',
        error: '#F04D4D',
        success: '#2AB59C',
        warning: '#EDA437',
        secondary: '#FFFFFF'
      }
    },
    options: {
      customProperties: true
    }
  }
})
