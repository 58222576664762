// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const GET_ORDER = gql`
  query ($id: ID!) {
    order(id: $id) {
      id
      price
      address
      customer {
        id
        addressList
        address,
        name
        tel
        taxId
        email
      }
      promoCodeDiscount
      shippingType
      shippingFee
      createdAt
      transferredAt
      printedAt
      packedAt
      closedAt
      closedNote
      markTransferredAt
      paymentRejected
      paymentAccepted
      paymentAcceptedAt
      paidAmount
      orderBank
      orderBankDisplayName
      scbQrCode
      purchases {
        id
        name
        price
        quantity
        pictureUrl
        stockAvailable
      }
      setPurchases {
        id
        name
        price
        quantity
        pictureUrl
        stockAvailable
      }
    }
  }
`

export const GET_ORDERS = gql`
  query {
    orders (page: 1, perPage: 999) {
      nodes {
        purchases {
          id
        }
      }
      nodesCount
      pagesCount
    }
  }
`

export default {
  GET_ORDER,
  GET_ORDERS
}
