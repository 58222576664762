import Vue from 'vue'
import ShipnityPlugin from '@shipnity/template'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(ShipnityPlugin)

export default new ShipnityPlugin({
  notification: {
    position: 'top'
  },
  theme: {
    themes: {
      light: {
        primary: '#1BA7E1',
        error: '#F04D4D',
        success: '#2AB59C',
        warning: '#EDA437',
        secondary: '#6C6C6C',
        background: '#F4F6FA'
      },
      dark: {
        primary: '#1BA7E1',
        error: '#F04D4D',
        success: '#2AB59C',
        warning: '#EDA437',
        secondary: '#FFFFFF'
      }
    }
  }
})
