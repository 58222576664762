// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const GET_SETTING = gql`
  query {
    setting {
      name
    }
  }
`

export const GET_SETTING_USER = gql`
  query {
    setting {
      id
      name
      maxUsers
      availableAndReservedCredit
    }
  }
`

export default {
  GET_SETTING,
  GET_SETTING_USER
}
