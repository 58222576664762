<template>
  <v-snackbar
    :value="isActive"
    :timeout="timeout"
    :color="color"
    elevation="0"
    top
    @input="change($event)">
    <div class="snackbar">
      <v-icon
        v-if="icon">
        {{ icon }}
      </v-icon>
      <div class="snackbar__msg">
        {{ message }}
      </div>
      <v-spacer />
      <v-btn
        icon
        dark
        x-small
        @click="change(false)">
        <v-icon
          small>
          mdi-close
        </v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import Vue from 'vue'
import { VSnackbar, VIcon, VSpacer, VBtn } from 'vuetify/lib'

export default Vue.extend({
  components: { VSnackbar, VIcon, VSpacer, VBtn },
  props: {
    type: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: 'mdi-bell'
    },
    color: {
      type: String,
      default: undefined
    },
    message: {
      type: String,
      required: true
    },
    timeout: {
      type: Number,
      default: 3000
    }
  },
  data () {
    return {
      isActive: true
    }
  },
  beforeMount () {
    this.setupContainer()
  },
  mounted () {
    this.show()
  },
  methods: {
    show () {
      if (this.shouldQueue()) {
        return
      }
      this.parentTop.insertAdjacentElement('afterbegin', this.$el)
      this.isActive = true
    },
    shouldQueue () {
      return (
        this.parentTop.childElementCount > 0
      )
    },
    setupContainer () {
      this.parentTop = document.querySelector('.v-toast')
      if (this.parentTop && this.parentBottom) return
      if (!this.parentTop) {
        this.parentTop = document.createElement('div')
        this.parentTop.className = 'v-toast'
      }
      const container = document.body
      container.appendChild(this.parentTop)
    },
    dismiss () {
      this.isActive = false
      setTimeout(() => {
        this.$destroy()
        if (typeof this.$el.remove !== 'undefined') {
          this.$el.remove()
        } else {
          this.$el.parentNode.removeChild(this.$el)
        }
      }, 150)
    },
    change (value) {
      if (!value) {
        this.dismiss()
      }
    }
  }
})
</script>

<style scoped>
  .snackbar {
    display: flex;
    align-items: center;
  }
  .snackbar__msg {
    font-family: prompt,sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin: 0 8px;
  }
</style>
