import _ from 'lodash'

export function importAll (r, prefix = '') {
  const components = {}
  r.keys().forEach((key) => {
    const name = _.snakeCase(_.words(_.split(key, '.svg')[0]))
    components[`${prefix}_${name}`] = {
      component: r(key)
    }
  })
  return components
}

export function importAllDeep (r, prefix = '') {
  const components = {}
  r.keys().forEach((key) => {
    const name = _.capitalize(_.words(_.split(key, '.svg')[0]))
    components[`${prefix}${name}`] = r(key)
  })
  return components
}

export default {
  importAll,
  importAllDeep
}
