// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const GET_ME = gql`
  query  {
    me {
      id
      email
      admin
      stocks {
        id
        name
        isMain
      }
      role {
        id
        accessSetting
        settingOrderPage
        settingStock
        settingManageUser
        settingRole
        settingReseller
        settingUnit
        settingManageMessage
        settingI18n
        settingPacking
        settingProduct
        settingVat
        settingPromoCode
        settingCrm
        settingShippingCalculator
        settingCreditCard
        settingCustomPrint
        settingBankAccount
        changePassword,
        reportView
        reportCreate
        reportEdit
        reportFilter
        reportDelete
        reportMoveAndResize
        reportExport
      }
    }
  }
`

export default {
  GET_ME
}
