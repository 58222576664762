import _ from 'lodash'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import Cookies from 'js-cookie'
import { ApolloClient } from 'apollo-client'
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import store from '@/store'
import Notification from '@/services/notification'

Vue.use(VueApollo)

const httpLink = createUploadLink({
  uri: process.env.VUE_APP_API,
  credentials: 'include'
})

const authLink = setContext(({ headers }) => {
  const token = Cookies.get('CSRF-TOKEN')
  return {
    headers: {
      ...headers,
      'X-CSRF-Token': token || null
    }
  }
})

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})
const debounceLoading = _.debounce((isLoading) => {
  store.dispatch('app/setLoading', isLoading)
}, 500)
const notify = new Notification()
const apollo = new VueApollo({
  defaultClient: apolloClient,
  watchLoading (isLoading) {
    debounceLoading(isLoading)
  },
  errorHandler (error) {
    notify.error(`ไม่สามารถเชื่อมต่อกับเซิฟเวอร์ได้ (${error.message})`)
  }
})

export default apollo
