// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const GET_STOCKS = gql`
  query {
    stocks {
      nodes {
        id
        isMain
        name
      }
    }
  }
`

export default {
  GET_STOCKS
}
