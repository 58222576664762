// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const CREATE_PAYORDER = gql`
  mutation CreatePayOrder ($input: PayOrderInput!) {
    payOrder (input: $input) {
      order {
        id
        price
      }
      errors
    }
  }
`

export const CREATE_ORDER = gql`
  mutation CreateOrder ($input: CreateOrderInput!) {
    createOrder (input: $input) {
      order {
        id
        price
        customer {
          id
          addressList
          address,
          name
          tel
          taxId
          email
        }
      }
      errors
    }
  }
`

export default {
  CREATE_PAYORDER,
  CREATE_ORDER
}
