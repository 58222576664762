<template>
  <snt-context>
    <snt-router :key="$route.fullPath" />
    <loading />
  </snt-context>
</template>

<script>
import Loading from '@/components/Loading.vue'

export default {
  name: 'App',
  components: { Loading }
}
</script>

<style lang="scss">
  @import '~@/assets/scss/styles.scss';
  @import '~@/assets/scss/vuetify.scss';
</style>
