import Vue from 'vue'
import VueRouter from 'vue-router'
import PageErrorRoutes from '@/router/routes/page-error'
import { apolloClient } from '@/plugins/vue-apollo'
import store from '@/store'
import { GET_ME, GET_SETTING } from '@/resources/graphql'
import { ifAuthenticated, isAdmin } from '@/router/guards/auth.guard'
import genRoutes from '@shipnity/template/src/utils/genRoutes'

Vue.use(VueRouter)
const salePageRoutes = genRoutes({
  publicPath: '/templates/:tid/sale-page'
}).map(({ name, path }) => {
  const route = {
    name,
    path,
    component: () => import('@/views/sale-page/SalePage.vue')
  }
  switch (name) {
    case 'Home':
      return route
    case 'SalePage':
      return {
        ...route,
        props: {
          propName: 'landing'
        }
      }
    case 'SalePageProducts':
      return {
        ...route,
        props: {
          propName: 'products'
        }
      }
    case 'SalePageCategoryProducts':
      return {
        ...route,
        props: {
          propName: 'products'
        }
      }
    case 'SalePageProductDetail':
      return {
        ...route,
        props: {
          propName: 'product'
        }
      }
    case 'SalePageCollection':
      return {
        ...route,
        props: {
          propName: 'products'
        }
      }
    case 'SalePageCategoryCollection':
      return {
        ...route,
        props: {
          propName: 'products'
        }
      }
    case 'SalePageCollectionDetail':
      return {
        ...route,
        props: {
          propName: 'product'
        }
      }
    case 'SalePageCheckout':
      return {
        ...route,
        props: {
          propName: 'checkout'
        }
      }
    case 'SalePagePayment':
      return {
        ...route,
        props: {
          propName: 'payment'
        }
      }
    case 'SalePageCart':
      return {
        ...route,
        props: {
          propName: 'cart'
        }
      }
    case 'SalePageOrders':
      return {
        ...route,
        props: {
          propName: 'orders'
        }
      }
    case 'SalePageOrder':
      return {
        ...route,
        props: {
          propName: 'order'
        }
      }
    case 'Page':
        return {
          ...route,
          props: {
            propName: 'pages'
          }
        }
      default:
        return {
          ...route,
          props: {
            propName: 'landing'
          }
        }
  }
})
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue')
  },
  {
    path: '/',
    name: 'Templates',
    alias: ['/templates'],
    component: () => import('@/views/templates/Templates.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/templates/:tid',
    name: 'Template',
    component: () => import('@/views/template/Template.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/editor/:slug',
    name: 'Editor',
    component: () => import('@/views/editor/Editor.vue')
  },
  {
    path: '/admin',
    component: () => import('@/views/admin/Admin.vue'),
    beforeEnter: (to, from, next) => isAdmin(next),
    children: [
      {
        path: '',
        name: 'Admin',
        component: () => import('@/views/admin/pages/home/Home.vue')
      },
      {
        path: 'templates',
        name: 'AdminTemplates',
        component: () => import('@/views/admin/pages/templates/Templates.vue')
      }
    ]
  },
  ...salePageRoutes,
  PageErrorRoutes
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from) {
    if (to.path === from.path) {
      return null
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('app/setLoading', true)
  const { data: { me: { id, admin } } } = await apolloClient.query({
    query: GET_ME
  }).catch(() => {
    window.location.href = process.env.VUE_APP_SHIPNITY
  })
  const { data: { setting: { name } } } = await apolloClient.query({
    query: GET_SETTING
  })
  store.dispatch('auth/setUserId', Number(id))
  store.dispatch('auth/setIsAdmin', admin)
  store.dispatch('auth/setShopName', name)
  store.dispatch('app/setLoading', false)
  next()
})

export default router
