import store from '@/store'

export const ifAuthenticated = (to, from, next) => (store.getters['auth/userId'] ? next() : next({
  name: 'AccessDenied'
}))

export const hasPermission = (next, permission) => (
  ifAuthenticated && store.getters['auth/permission'][permission]
  ? next()
  : next({
    name: 'AccessDenied'
  }))

export const isAdmin = (next) => (
  ifAuthenticated && store.getters['auth/isAdmin']
  ? next()
  : next({
    name: 'AccessDenied'
  }))

export default {
  ifAuthenticated,
  hasPermission,
  isAdmin
}
