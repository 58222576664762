const getDefaultState = (key) => {
  const state = {
    isLoading: false,
    mini: true,
    viewPage: 'Home',
    coding: false,
    designing: false
  }
  return state[key] || state
}
const state = getDefaultState()

const mutations = {
  SET_MINI (state, payload) {
    state.mini = payload
  },
  SET_CODING (state, payload) {
    state.coding = payload
  },
  SET_DESIGNING (state, payload) {
    state.designing = payload
  },
  SET_VIEWPAGE (state, payload) {
    state.viewPage = payload
  },
  SET_LOADING (state, payload) {
    state.isLoading = payload
  }
}

const actions = {
  setMini ({ commit }, payload) {
    commit('SET_MINI', payload)
  },
  setCoding ({ commit }, payload) {
    commit('SET_CODING', payload)
  },
  setDesigning ({ commit }, payload) {
    commit('SET_DESIGNING', payload)
  },
  setViewPage ({ commit }, payload) {
    commit('SET_VIEWPAGE', payload)
    commit('local/SET_VIEWPAGE', payload, { root: true })
  },
  setLoading ({ commit }, payload) {
    commit('SET_LOADING', payload)
  }
}

const getters = {
  isLoading: (state) => state.isLoading,
  mini: (state) => state.mini,
  coding: (state) => state.coding,
  designing: (state) => state.designing,
  viewPage: (state, _, rootState) => rootState.local.viewPage || state.viewPage
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
