import _ from 'lodash'

const getDefaultState = (key) => {
  const state = {
    name: '',
    pages: [],
    currentPage: null,
    currentFile: null,
    files: []
  }
  return state[key] || state
}

const state = getDefaultState()

const mutations = {
  SET_NAME (state, payload) {
    state.name = payload
  },
  SET_PAGES (state, payload) {
    state.pages = payload
  },
  SET_CURRENTPAGE (state, payload) {
    state.currentPage = payload
  },
  SET_CURRENTFILE (state, payload) {
    state.currentFile = payload
  },
  SET_FILES (state, payload) {
    state.files = payload
  }
}

const actions = {
  setName ({ commit }, payload) {
    commit('SET_NAME', payload)
  },
  addPages ({ commit, state }, payload) {
    const pages = _.concat(state.pages, payload)
    commit('SET_PAGES', _.uniqBy(pages, 'key'))
  },
  setCurrentPage ({ commit }, payload) {
    commit('SET_CURRENTPAGE', payload)
  },
  setCurrentFile ({ commit }, payload) {
    commit('SET_CURRENTFILE', payload)
  },
  clearFiles ({ commit }) {
    commit('SET_FILES', [])
  },
  openFile ({ commit, state }, payload) {
    const files = _.cloneDeep(state.files)
    files.push(payload)
    commit('SET_FILES', _.uniqBy(files, 'key'))
    commit('SET_CURRENTFILE', payload.key)
  },
  closeFile ({ commit, state }, payload) {
    const files = _.filter(state.files, (item) => item.key !== payload.key)
    commit('SET_FILES', files)
  },
  updateFile ({ commit, state }, payload) {
    const files = _.cloneDeep(state.files)
    const index = _.findIndex(files, ['key', payload.key])
    files[index] = payload
    commit('SET_FILES', files)
  },
  resolveFiles ({ commit, state }) {
    const files = _.cloneDeep(state.files)
    commit('SET_FILES', files.map((file) => _.find(state.pages, (item) => item.key === file.key)))
  }
}

const getters = {
  name: (state) => state.name,
  pages: (state) => state.pages,
  currentPage: (state) => state.currentPage,
  currentFile: (state) => state.currentFile,
  currentFileObj: (state) => _.find(state.files, ['key', state.currentFile]),
  files: (state) => _.cloneDeep(state.files)
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
