const defaultState = {
  csrf: null,
  userId: null,
  permission: {},
  shopName: null,
  isAdmin: false,
  email: null
}

const mutations = {
  SET_USER_ID (state, payload) {
    state.userId = payload
  },
  SET_CSRF (state, payload) {
    state.csrf = payload
  },
  SET_PERMISSION (state, payload) {
    state.permission = payload
  },
  SET_SHOPNAME (state, payload) {
    state.shopName = payload
  },
  SET_ISADMIN (state, payload) {
    state.isAdmin = payload
  },
  SET_EMAIL (state, payload) {
    state.email = payload
  }
}

const actions = {
  setUserId ({ commit }, payload) {
    commit('SET_USER_ID', payload)
  },
  setCsrf ({ commit }, payload) {
    commit('SET_CSRF', payload)
  },
  setPermission ({ commit }, payload) {
    commit('SET_PERMISSION', payload)
  },
  setShopName ({ commit }, payload) {
    commit('SET_SHOPNAME', payload)
  },
  setIsAdmin ({ commit }, payload) {
    commit('SET_ISADMIN', payload)
  },
  setEmail ({ commit }, payload) {
    commit('SET_EMAIL', payload)
  }
}

const getters = {
  userId: (state) => state.userId,
  csrf: (state) => state.csrf,
  permission: (state) => state.permission,
  shopName: (state) => state.shopName,
  isAdmin: (state) => state.isAdmin,
  email: (state) => state.email
}

export default {
  namespaced: true,
  state: defaultState,
  actions,
  mutations,
  getters
}
