const getDefaultState = (key) => {
  const state = {
    viewPage: 'Home',
    theme: {
      color: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF'
      }
    }
  }
  return state[key] || state
}
const state = getDefaultState()

const mutations = {
  SET_VIEWPAGE (state, payload) {
    state.viewPage = payload
  },
  SET_THEME (state, payload) {
    state.theme = payload
  }
}

const actions = {
  setViewPage ({ commit }, payload) {
    commit('SET_VIEWPAGE', payload)
  },
  setTheme ({ commit }, payload) {
    commit('SET_THEME', payload)
  }
}

const getters = {
  viewPage: (state) => state.viewPage,
  theme: (state) => state.theme
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
