// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const GET_SALEPAGETEMPLATECATEGORY_ENUM = gql`
  query {
    __type(name: "SalePageTemplateCategoryEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export const GET_SALEPAGETEMPLATEVERSIONUPDATETYPE_ENUM = gql`
  query {
    __type(name: "SalePageTemplateVersionUpdateTypeEnum") {
      enumValues {
        description
        name
      }
    }
  }
`
export const GET_SALEPAGETEMPLATESTATUS_ENUM = gql`
  query {
    __type(name: "SalePageTemplateStatusEnum") {
      enumValues {
        description
        name
      }
    }
  }
`

export default {
  GET_SALEPAGETEMPLATESTATUS_ENUM,
  GET_SALEPAGETEMPLATECATEGORY_ENUM,
  GET_SALEPAGETEMPLATEVERSIONUPDATETYPE_ENUM
}
