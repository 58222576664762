// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const GET_SALEPAGES = gql`
  query SalePages {
    salePages (page: 1, perPage: 100) {
      nodes {      
        id
        domain
        salePageTemplate {
          id
          name
        }
        stock {
          id
          name
        }
      }
    }
  }
`

export const GET_SALEPAGE = gql`
  query SalePage($id: ID, $domain: String) {
    salePage(id: $id, domain: $domain) {
      id
      domain
      version
      header  
      landing
      product
      products
      cart
      order
      orders
      checkout
      payment
      footer
      stylesheet
      headerKeys
      landingKeys
      productsKeys
      productKeys
      cartKeys
      orderKeys
      ordersKeys
      checkoutKeys
      paymentKeys
      footerKeys
      headerValues
      landingValues
      productValues
      productsValues
      checkoutValues
      paymentValues
      cartValues
      orderValues
      ordersValues
      footerValues
      headerSettings
      landingSettings
      productSettings
      productsSettings
      checkoutSettings
      paymentSettings
      cartSettings
      orderSettings
      ordersSettings
      footerSettings
      headerPublish
      landingPublish
      productPublish
      productsPublish
      checkoutPublish
      cartPublish
      orderPublish
      ordersPublish
      paymentPublish
      footerPublish
      published
      customConfig
      salePageTemplate {
        id
        name
        version
        hasNewerVersion
        latestVersion {
          id
          version
        }
      }
      productBrandList (perPage: 250) {
        nodes {
          id
          name
        }
      }
      productCategoryList (perPage: 250) {
        nodes {
          id
          name
        }
      }
      stock {
        id
      }
    }
  }
`

export const GET_SALEPAGE_PRODUCTS = gql`
  query (
    $id: ID,
    $domain: String,
    $page: Int,
    $perPage: Int,
    $search: String,
    $categoryId: [ID!],
    $brandId: [ID!],
    $items: [ID!],
    $orderBy: SalePageProductOrderBy
  ) {
  salePage (id: $id, domain: $domain) {
    id
    productList(
      page: $page, 
      perPage: $perPage, 
      filter: {
        search: $search,
        categoryId: $categoryId,
        brandId: $brandId,
        id: $items
      }
      orderBy: $orderBy
    ) {
      nodes {
        id
        name
        price
        code
        images
        thumbUrl
        pictureUrl
        available
        descriptionShort
        descriptionLong
        displayOnSale
        fullPrice
        productBrand {
          id
          name
        }
        productCategory {
          id
          name
        }
        subproducts {
          id
          name
          price
          inventory
          available
        }
      }
      nodesCount
      pagesCount
    }
  }
}
`

export const GET_SALEPAGE_SETPRODUCTS = gql`
  query (
    $id: ID,
    $domain: String,
    $page: Int,
    $perPage: Int,
    $search: String,
    $categoryId: [ID!],
    $brandId: [ID!],
    $items: [ID!],
    $orderBy: SalePageProductSetOrderBy
  ) {
  salePage (id: $id, domain: $domain) {
    id
    productSetList(
      page: $page, 
      perPage: $perPage, 
      filter: {
        search: $search,
        categoryId: $categoryId,
        brandId: $brandId,
        id: $items,
      }
      orderBy: $orderBy
    ) {
      nodes {
        id
        name
        price
        images
        available
        descriptionShort
        descriptionLong
        pictureUrl
        displayOnSale
        fullPrice
        subproducts {
          id
          name
          price
          inventory
          available
        }
      }
      nodesCount
      pagesCount
    }
  }
}
`

export const GET_SALEPAGE_BANKACCOUNTS = gql`
  query ($id: ID, $domain: String) {
    salePage (id: $id, domain: $domain) {
      id
      stock {
        id
        scbPayment
      }
      bankAccountList {
        id
        account
        accountBranch
        accountName
        bank
      }
    }
  }
`

export const GET_SALEPAGE_PROMOCODES = gql`
  query ($id: ID, $domain: String, $search: String, $page: Int, $perPage: Int) {
    salePage (id: $id, domain: $domain) {
      id
      promoCodeList(
        filter: {
          search: $search
        }, 
        page: $page, 
        perPage: $perPage
      ) {
        nodesCount
        pagesCount
        nodes {
          id
          code
          endDate
          pointUsed
          discountValue
          discountType
          claimable
          minRedeemType
          minRedeemValue
        }
      }
    }
  }
`

export const GET_SALEPAGE_ALLPROMOCODES = gql`
  query ($id: ID, $domain: String, $customerId: ID!, $search: String, $page: Int, $perPage: Int) {
    salePage (id: $id, domain: $domain) {
      id
      findCustomer(id: $customerId) {
        id
        activeClaimedPromoCodes(
          page: $page, 
          perPage: $perPage
        ) {
          nodesCount
          pagesCount
          nodes {
            id
            code
            endDate
            pointUsed
            discountValue
            discountType
            claimable
            minRedeemType
            minRedeemValue
          }
        }
      }
      promoCodeList(
        filter: {
          search: $search
        }, 
        page: $page, 
        perPage: $perPage
      ) {
        nodesCount
        pagesCount
        nodes {
          id
          code
          endDate
          pointUsed
          discountValue
          discountType
          claimable
          minRedeemType
          minRedeemValue
        }
      }
    }
  }
`

export const GET_SALEPAGE_CHECKDOMAIN = gql`
  query SalePageCheckDomain($domain: String!) {
    salePageCheckDomain(domain: $domain)
  }
`

export const GET_SALEPAGE_TRACKING = gql`
  query ($id: ID, $domain: String, $customerId: ID!, $orderId: [ID!]) {
    salePage(id: $id, domain: $domain) {
      id
      findCustomer(id: $customerId) {
        id
        orderList (
          page: 1, 
          perPage: 1
          filter: {
            id: $orderId
          }
        ) {
          nodes {
            id
            shippingType
            closedNote
            shippingStatus
            shippingLinkClosedNote
          }
        }
      }
    }
  }
`

export const GET_SALEPAGE_SHIPPING = gql`
  query ($id: ID, $domain: String, $input: SalePageShippingListFilter) {
    salePage (id: $id, domain: $domain) {
      id
      shippingList(input: $input)
      stock {
        id
        shop {
          id
          defaultShippingType 
        }
      }
    }
  }
`

export const GET_SALEPAGE_ORDER = gql`
  query ($id: ID, $domain: String, $customerId: ID!, $orderId: [ID!]) {
    salePage (id: $id, domain: $domain) {
      id
      findCustomer (id: $customerId) {
        id
        orderList (
          page: 1, 
          perPage: 1
          filter: {
            id: $orderId
          }
        ) {
          nodes {
            id
            price
            address
            customer {
              id
              addressList
              address,
              name
              tel
              taxId
              email
            }
            promoCodeDiscount
            shippingType
            shippingFee
            createdAt
            transferredAt
            printedAt
            packedAt
            closedAt
            closedNote
            markTransferredAt
            paymentRejected
            paymentAccepted
            paymentAcceptedAt
            paymentSlipUrl
            paidAmount
            orderBank
            orderBankDisplayName
            scbQrCode
            purchases {
              id
              name
              price
              quantity
              pictureUrl
              stockAvailable
            }
            setPurchases {
              id
              name
              price
              quantity
              pictureUrl
              stockAvailable
            }
            shippingLinkClosedNote
            shippingStatus
            salePageStatus
          }
        }
      }
    }
  }
`

export const GET_SALEPAGE_ORDERS = gql`
  query ($id: ID, $domain: String, $customerId: ID!, $page: Int, $perPage: Int, $status: CustomerOrderStageFilterEnum) {
    salePage (id: $id, domain: $domain) {
      id
      findCustomer (id: $customerId) {
        id
        orderList (
          page: $page, 
          perPage: $perPage
          filter: {
            status: $status
          }
        ) {
          nodesCount
          pagesCount
          nodes {
            id
            price
            closed
            taxable
            shippop
            printed
            paymentAccepted
            paymentRejected
            cancelled
            closed
            packed
            paidAmount
            expirationDate
            invoiceNumber    
            transferredAt
            printedAt
            packedAt
            closedAt
            markTransferredAt
            paymentAcceptedAt
            orderBank
            orderBankDisplayName
            purchases {
              id
              name
              price
              quantity
              pictureUrl
              productStockId
              stockAvailable
              subproduct {
                id
                name
                price
                available
              }
            }
            setPurchases {
              id
              name
              price
              quantity
              pictureUrl
              stockAvailable
              productSet {
                id
                name
                price
                available
              }
            }
            shippingType
            closedNote 
            shippingLinkClosedNote
            shippingStatus
            salePageStatus
          }
        }
      }
    }
  }
`

export const GET_SALEPAGE_CUSTOMER = gql`
  query ($id: ID, $domain: String, $customerId: ID, $tel: String, $postcode: String) {
    salePage (id: $id, domain: $domain) {
      id
      findCustomer (id: $customerId, tel: $tel, postcode: $postcode) {
        id
        addressList
        address,
        name
        tel
        taxId
        email
      }
    }
  } 
`

export const GET_SALEPAGE_STOCK = gql`
  query ($id: ID, $domain: String) {
    salePage(id: $id, domain: $domain) {
      id
      stock {
        id
        scbPayment
      }
    }
  }
`

export const GET_SALEPAGE_PRODUCT_CATEGORIES = gql`
  query ($id: ID, $domain: String) {
    salePage(id: $id, domain: $domain) {
      id
      productCategoryList(perPage: 250) {
        nodes {
          id
          name
          productsCount
          threshold
        }
      }
    }
  }
`

export const GET_SALEPAGE_PRODUCT_BRANDS = gql`
  query ($id: ID, $domain: String) {
    salePage(id: $id, domain: $domain) {
      id
      productBrandList(perPage: 250) {
        nodes {
          id
          name
          productsCounts
        }
      }
    }
  }
`

export const GET_SALEPAGE_ORDER_CREDITCARD = gql`
  query ($id: ID, $domain: String, $customerId: ID!, $orderId: [ID!], $resultUrl: String) {
    salePage (id: $id, domain: $domain) {
      id
      findCustomer (id: $customerId) {
        id
        orderList (
          page: 1, 
          perPage: 1
          filter: {
            id: $orderId
          }
        ) {
          nodes {
            id
            creditCardFormData (resultUrl: $resultUrl)
          }
        }
      }
    }
  }
`

export const GET_SALEPAGE_CALC_PRICE = gql`
  query ($id: ID, $domain: String, $purchases: [CreatePurchaseInput!]!) {
    salePage (id: $id, domain: $domain) {
      id
      calculatePrice (purchases: $purchases) {
        code
        price
        discount
        quantity
        subproductId
        name
      }
    }
  }
`

export default {
  GET_SALEPAGE,
  GET_SALEPAGES,
  GET_SALEPAGE_PRODUCTS,
  GET_SALEPAGE_SETPRODUCTS,
  GET_SALEPAGE_CHECKDOMAIN,
  GET_SALEPAGE_BANKACCOUNTS,
  GET_SALEPAGE_PROMOCODES,
  GET_SALEPAGE_ALLPROMOCODES,
  GET_SALEPAGE_TRACKING,
  GET_SALEPAGE_SHIPPING,
  GET_SALEPAGE_ORDERS,
  GET_SALEPAGE_ORDER,
  GET_SALEPAGE_CUSTOMER,
  GET_SALEPAGE_STOCK,
  GET_SALEPAGE_PRODUCT_CATEGORIES,
  GET_SALEPAGE_PRODUCT_BRANDS,
  GET_SALEPAGE_ORDER_CREDITCARD,
  GET_SALEPAGE_CALC_PRICE
}
