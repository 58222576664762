// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const GET_PRODUCTS = gql`
  query (
    $page: Int,
    $perPage: Int,
    $stockId: ID,
    $search: String,
    $categoryId: ID,
    $brandId: ID,
    $orderBy: ProductOrderBy,
    $id:[ID!],
    $hasPicture: Boolean,
    $OR: [ProductFilter!]
  ) {
    products(
      page: $page,
      perPage: $perPage,
      orderBy: $orderBy,
      filter: {
        OR: $OR,
        stockId: $stockId,
        search: $search,
        id: $id,
        hasPicture: $hasPicture,
        categoryId: $categoryId,
        brandId: $brandId
      }
    ) {
      nodesCount
      pagesCount
      nodes {
        id
        name
        price
        code
        images
        thumbUrl
        pictureUrl
        available
        descriptionShort
        descriptionLong
        displayOnSale
        fullPrice
        productBrand {
          id
          name
        }
        productCategory {
          id
          name
        }
        subproducts {
          id
          name
          price
          inventory
          available
        }
      }
    }
  }
`

export const GET_PRODUCTSETS = gql`
  query (
    $page: Int,
    $perPage: Int,
    $stockId: ID,
    $search: String,
    $categoryId: ID,
    $brandId: ID,
    $orderBy: ProductSetOrderBy,
    $id:[ID!]
  ) {
    productSets(
      page: $page,
      perPage: $perPage,
      orderBy: $orderBy,
      filter: {
        stockId: $stockId,
        search: $search,
        categoryId: $categoryId,
        brandId: $brandId,
        id: $id
      }
    ) {
      nodesCount
      pagesCount
      nodes {
        id
        name
        price
        images
        available
        descriptionShort
        descriptionLong
        pictureUrl
        displayOnSale
        fullPrice
        subproducts {
          id
          name
          price
          inventory
          available
        }
      }
    }
  }
`
export const GET_PRODUCT = gql`
  query ($id: ID!) {
    product(id: $id) {
      id
    }
  }
`
export const GET_PRODUCT_CATEGORIES = gql`
  query {
    productCategories {
      threshold
      productsCount
      name
      id
    }
  }
`
export const GET_PRODUCT_BRANDS = gql`
  query {
    productBrands {
      id
      productsCounts
      name
    }
  }
`
export default {
  GET_PRODUCT,
  GET_PRODUCTS,
  GET_PRODUCTSETS,
  GET_PRODUCT_CATEGORIES,
  GET_PRODUCT_BRANDS
}
