// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const GET_SALEPAGETEMPLATE = gql`
  query SalePageTemplate($id: ID!) {
    salePageTemplate(id: $id) {
      id
      product
      products
      name
      header
      landing
      footer
      checkout
      order
      orders
      cart
      payment
      categories
      version
      stylesheet
    }
  }
`

export const GET_SALEPAGETEMPLATES = gql`
  query SalePageTemplates(
    $page: Int!,
    $perPage: Int!,
    $filter: SalePageTemplateFilter,
    $orderBy: [SalePageTemplateOrderBy!] 
  ) {
    salePageTemplates(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
      hasNextPage
      nodes {      
        id
        salePagesCount
        componentList
        sectionList
        snapshotUrl
        product
        products
        name
        price
        header
        landing
        footer
        checkout
        cart
        order
        orders
        payment
        categories
        version
        stylesheet
        latestStatus
      }
    }
  }
`

export const GET_SALEPAGETEMPLATELIST = gql`
  query SalePageTemplateList(
    $page: Int!,
    $perPage: Int!,
    $filter: SalePageTemplateListFilter,
    $orderBy: [SalePageTemplateListOrderBy!] 
  ) {
    salePageTemplateList(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy){
      pagesCount
      nodesCount
      hasNextPage
      nodes {
        id
        name
        categories
        version
        price
        product
        products
        header
        landing
        footer
        checkout
        order
        orders
        cart
        payment
        snapshotUrl
        stylesheet
        sectionList
        sectionsCount
        latestStatus
        createdAt
        user {
          email
        }
      }
    }
  }
`

export const GET_SALEPAGECOMPONENTLIST = gql`
  query SalePageComponentList ($sorting: SalePageFilterableComponentSortingEnum) {
    salePageComponentList (sorting: $sorting)
  }
`

export default {
  GET_SALEPAGETEMPLATE,
  GET_SALEPAGETEMPLATES,
  GET_SALEPAGETEMPLATELIST,
  GET_SALEPAGECOMPONENTLIST
}
