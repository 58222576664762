// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const CREATE_SALEPAGE = gql`
  mutation CreateSalePage ($input:  CreateSalePageInput!) {
    createSalePage (input: $input) {
      salePage {
        id
      }
      errors
    }
  }
`

export const UPDATE_SALEPAGE = gql`
  mutation UpdateSalePage ($input: UpdateSalePageInput!) {
    updateSalePage (input: $input) {
      salePage {
        id
      }
      errors
    }
  }
`

export const PUBLISH_SALEPAGE = gql`
  mutation PublishSalePage ($input:  PublishSalePageInput!) {
    publishSalePage (input: $input) {
      salePage {
        id
      }
      errors
    }
  }
`

export const UPLOAD_SALEPAGE_IMAGE = gql`
  mutation UploadSalePageImage ($input: UploadSalePageImageInput!) {
    uploadSalePageImage(input: $input) {
      imageUrl
      errors
    }
  }
`

export const DELETE_SALEPAGE = gql`
  mutation DeleteSalePage ($input: DeleteSalePageInput!) {
    deleteSalePage (input: $input) { 
      clientMutationId
    }
  }
`

export default {
  CREATE_SALEPAGE,
  UPDATE_SALEPAGE,
  PUBLISH_SALEPAGE,
  UPLOAD_SALEPAGE_IMAGE,
  DELETE_SALEPAGE
}
