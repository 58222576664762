// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const GET_BANK_ACCOUNTS = gql`
  query ($page: Int, $perPage: Int) {
    bankAccounts(page: $page, perPage: $perPage) {
      nodes {
        id
        account
        accountBranch
        accountName
        bank
        stock {
          id
          name
          scbPayment
          isMain
        }
      }
      pagesCount
      nodesCount
    }
  }
`

export default {
  GET_BANK_ACCOUNTS
}
