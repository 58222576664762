// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const GET_PROMOCODES = gql`
  query {
    promoCodes (page: 1, perPage: 100) {
      nodes {
        id
      }
    }
  } 
`

export default {
  GET_PROMOCODES
}
