// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const GET_SHIPPING_CALCULATORS = gql`
  query ($page: Int, $perPage: Int, $orderBy: ShippingCalculatorOrderBy = shipping_type_ASC) {
    shippingCalculators(
      page: $page,
      perPage: $perPage,
      orderBy: $orderBy
    ) {
      nodes {
        id
        shippingType
        calculationMethod
        orderedShippingCalculations {
          id
          fee
          value
        }
        shippingCalculations {
          id
          fee
          value
        }
      }
    }
  }
`

export default {
  GET_SHIPPING_CALCULATORS
}
