// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const GET_UNSPLASHPHOTOS = gql`
  query UnsplashPhotos($page: Int, $perPage: Int, $search: String)  {
    unsplashPhotos(search: $search, page: $page, perPage: $perPage)
  }
`

export default {
  GET_UNSPLASHPHOTOS
}
