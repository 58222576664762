import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router/index'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import apolloProvider from '@/plugins/vue-apollo'
import i18n from '@/plugins/vue-i18n'
import shipnity from '@/plugins/vue-shipnity-template'
import Notification from '@/services/notification'
import '@/plugins/lodash'
import '@/plugins/vue-codemirror'
import '@/plugins/vue-infinite-loading'

Vue.config.productionTip = false
Vue.prototype.$notify = new Notification()
const createApp = () => new Vue({
  router,
  store,
  shipnity,
  vuetify,
  i18n,
  apolloProvider,
  render: (h) => h(App)
}).$mount('#app')

createApp()
