import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
// import SalePageModule from '@/store/modules/sale-page'
// import OrderModule from '@/store/modules/order'
// import CartModule from '@/store/modules/cart'
import TemplateModule from '@/store/modules/template'
import AuthModule from '@/store/modules/auth'
// import NotificationModule from '@/store/modules/notification'
import LocalModule from '@/store/modules/local'
import AppModule from '@/store/modules/app'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  key: 'local:salepagebuilder',
  storage: window.localStorage,
  reducer: (state) => ({
    version: '0.0.1',
    cart: state.cart,
    local: state.local,
    theme: state.theme
  })
})

const store = new Vuex.Store({
  modules: {
    // salePage: SalePageModule,
    // cart: CartModule,
    // order: OrderModule,
    template: TemplateModule,
    auth: AuthModule,
    // notify: NotificationModule,
    local: LocalModule,
    app: AppModule
  },
  plugins: [
    vuexLocal.plugin
  ]
})

if (window === window.top) {
  window._$store = store
} else {
  store.replaceState({
    ...store.state,
    local: window.parent._$store.state.local,
    cart: window.parent._$store.state.cart,
    // order: window.parent._$store.state.order,
    salePage: window.parent._$store.state.salePage
  })
}

export default store
