// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const CREATE_SALEPAGETEMPLATE = gql`
  mutation CreateSalePageTemplate ($input:  CreateSalePageTemplateInput!) {
    createSalePageTemplate (input: $input) {
      template {
        categories
        checkout
        footer
        header
        id
        landing
        name
        product
        products
        order
        orders
        payment
        cart
        stylesheet
        version
        snapshotUrl
      }
      errors
    }
  }
`

export const UPDATE_SALEPAGETEMPLATE = gql`
  mutation UpdateSalePageTemplate ($input:  UpdateSalePageTemplateInput!) {
    updateSalePageTemplate (input: $input) {
      template {
        categories
        checkout
        footer
        header
        id
        landing
        name
        price
        product
        products
        order
        orders
        payment
        cart
        stylesheet
        version
        snapshotUrl
      }
      errors
    }
  }
`

export const REVIEW_SALEPAGETEMPLATE = gql`
  mutation SubmitReviewSalePageTemplate ($input: SubmitReviewSalePageTemplateInput!) {
    submitReviewSalePageTemplate (input: $input) { 
      template {
        id
        latestStatus
      }
      errors
    }
  }
`

export const UPDATE_STATUS_SALEPAGETEMPLATE = gql`
  mutation ChangeStatusSalePageTemplate ($input: ChangeStatusSalePageTemplateInput!) {
    changeStatusSalePageTemplate(input: $input) {
      errors
      template {
        id
      }
    }
  }
`

export const DELETE_SALEPAGETEMPLATE = gql`
  mutation DeleteSalePageTemplate ($input: DeleteSalePageTemplateInput!) {
    deleteSalePageTemplate (input: $input) { 
      clientMutationId
    }
  }
`

export default {
  CREATE_SALEPAGETEMPLATE,
  UPDATE_SALEPAGETEMPLATE,
  DELETE_SALEPAGETEMPLATE,
  UPDATE_STATUS_SALEPAGETEMPLATE,
  REVIEW_SALEPAGETEMPLATE
}
